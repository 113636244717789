import { devicesService } from "~/services/devicesService"

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  nuxtApp.vueApp.$nuxt.$router.beforeEach(async (to, from, next) => {
    try {
      const session = await getUserSession()
      if (!session || !session.user) {
        if (to.path == "/login" || to.path.includes("/password-reset")) {
          next()
        } else {
          next("/login")
        }
        return
      }
      const user = session.user
      if (!user) {
        next("/login")
        return
      }
      if (user.superUserRoleId) {
        next()
        return
      }
      if (to.path.startsWith("/connections/")) {
        const iccid = to.params.iccid as string
        const organizationId = await devicesService.getOrganizationIdByIccid(iccid)
        if (organizationId != user.userOrganizationRoles[0].organizationId) {
          router.push("/companies/" + user.userOrganizationRoles[0].organizationId)
        } else {
          next()
          return
        }
      }

      if (user.userOrganizationRoles[0].organizationId != Number(to.params.organizationId)) {
        router.push("/companies/" + user.userOrganizationRoles[0].organizationId)
      }
      // }
      next()
      return
    } catch (error) {
      console.error("Error in beforeEach hook:", error)
      next()
    }
  })
})
