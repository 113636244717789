import { SessionWithUser } from "~/interfaces/SessionWithUser"

export default async function getUserSession() {
  const { getSession } = useAuth()
  const session = (await getSession()) as unknown as SessionWithUser
  if (!session || !session.user) {
    return { user: null }
  } else {
    delete session.user.password
    delete session.user.resetKey
    return session
  }
}
