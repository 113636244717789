class DevicesService {
  async getOrganizationIdByIccid(iccid: string) {
    try {
      const data = await $fetch(`/api/devices/${iccid}`, {
        method: "GET"
      })
      if (data) {
        return data.organizationId
      }
    } catch (error) {
      console.log(error, "from devicesService")
    }
  }
}
export const devicesService = new DevicesService()
