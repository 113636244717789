import { default as index0Gy2Nn7fjOMeta } from "/build/pages/companies/[organizationId]/index.vue?macro=true";
import { default as indexNKg6P3eQsaMeta } from "/build/pages/companies/index.vue?macro=true";
import { default as indexEpzDnCvsVAMeta } from "/build/pages/connections/[iccid]/index.vue?macro=true";
import { default as indexdN9W1ggG09Meta } from "/build/pages/connections/index.vue?macro=true";
import { default as dashboardnYsloppdz3Meta } from "/build/pages/dashboard.vue?macro=true";
import { default as indexKxn8JNUtLqMeta } from "/build/pages/login/index.vue?macro=true";
import { default as indexROkU7RQ9cRMeta } from "/build/pages/login/password-reset/[resetKey]/index.vue?macro=true";
import { default as indexnDVe213oF7Meta } from "/build/pages/reports/index.vue?macro=true";
import { default as indexMm3UB6RFhxMeta } from "/build/pages/super-users/index.vue?macro=true";
import { default as indexWTx6caecaFMeta } from "/build/pages/t-mobile-bulk/index.vue?macro=true";
import { default as indexkY6KMWHAqtMeta } from "/build/pages/template-plans/index.vue?macro=true";
import { default as index6DFRLHRx4YMeta } from "/build/pages/verizon-bulk/index.vue?macro=true";
export default [
  {
    name: index0Gy2Nn7fjOMeta?.name ?? "companies-organizationId",
    path: index0Gy2Nn7fjOMeta?.path ?? "/companies/:organizationId()",
    meta: index0Gy2Nn7fjOMeta || {},
    alias: index0Gy2Nn7fjOMeta?.alias || [],
    redirect: index0Gy2Nn7fjOMeta?.redirect || undefined,
    component: () => import("/build/pages/companies/[organizationId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexNKg6P3eQsaMeta?.name ?? "companies",
    path: indexNKg6P3eQsaMeta?.path ?? "/companies",
    meta: indexNKg6P3eQsaMeta || {},
    alias: indexNKg6P3eQsaMeta?.alias || [],
    redirect: indexNKg6P3eQsaMeta?.redirect || undefined,
    component: () => import("/build/pages/companies/index.vue").then(m => m.default || m)
  },
  {
    name: indexEpzDnCvsVAMeta?.name ?? "connections-iccid",
    path: indexEpzDnCvsVAMeta?.path ?? "/connections/:iccid()",
    meta: indexEpzDnCvsVAMeta || {},
    alias: indexEpzDnCvsVAMeta?.alias || [],
    redirect: indexEpzDnCvsVAMeta?.redirect || undefined,
    component: () => import("/build/pages/connections/[iccid]/index.vue").then(m => m.default || m)
  },
  {
    name: indexdN9W1ggG09Meta?.name ?? "connections",
    path: indexdN9W1ggG09Meta?.path ?? "/connections",
    meta: indexdN9W1ggG09Meta || {},
    alias: indexdN9W1ggG09Meta?.alias || [],
    redirect: indexdN9W1ggG09Meta?.redirect || undefined,
    component: () => import("/build/pages/connections/index.vue").then(m => m.default || m)
  },
  {
    name: dashboardnYsloppdz3Meta?.name ?? "dashboard",
    path: dashboardnYsloppdz3Meta?.path ?? "/dashboard",
    meta: dashboardnYsloppdz3Meta || {},
    alias: dashboardnYsloppdz3Meta?.alias || [],
    redirect: dashboardnYsloppdz3Meta?.redirect || undefined,
    component: () => import("/build/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexKxn8JNUtLqMeta?.name ?? "login",
    path: indexKxn8JNUtLqMeta?.path ?? "/login",
    meta: indexKxn8JNUtLqMeta || {},
    alias: indexKxn8JNUtLqMeta?.alias || [],
    redirect: indexKxn8JNUtLqMeta?.redirect || undefined,
    component: () => import("/build/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexROkU7RQ9cRMeta?.name ?? "login-password-reset-resetKey",
    path: indexROkU7RQ9cRMeta?.path ?? "/login/password-reset/:resetKey()",
    meta: indexROkU7RQ9cRMeta || {},
    alias: indexROkU7RQ9cRMeta?.alias || [],
    redirect: indexROkU7RQ9cRMeta?.redirect || undefined,
    component: () => import("/build/pages/login/password-reset/[resetKey]/index.vue").then(m => m.default || m)
  },
  {
    name: indexnDVe213oF7Meta?.name ?? "reports",
    path: indexnDVe213oF7Meta?.path ?? "/reports",
    meta: indexnDVe213oF7Meta || {},
    alias: indexnDVe213oF7Meta?.alias || [],
    redirect: indexnDVe213oF7Meta?.redirect || undefined,
    component: () => import("/build/pages/reports/index.vue").then(m => m.default || m)
  },
  {
    name: indexMm3UB6RFhxMeta?.name ?? "super-users",
    path: indexMm3UB6RFhxMeta?.path ?? "/super-users",
    meta: indexMm3UB6RFhxMeta || {},
    alias: indexMm3UB6RFhxMeta?.alias || [],
    redirect: indexMm3UB6RFhxMeta?.redirect || undefined,
    component: () => import("/build/pages/super-users/index.vue").then(m => m.default || m)
  },
  {
    name: indexWTx6caecaFMeta?.name ?? "t-mobile-bulk",
    path: indexWTx6caecaFMeta?.path ?? "/t-mobile-bulk",
    meta: indexWTx6caecaFMeta || {},
    alias: indexWTx6caecaFMeta?.alias || [],
    redirect: indexWTx6caecaFMeta?.redirect || undefined,
    component: () => import("/build/pages/t-mobile-bulk/index.vue").then(m => m.default || m)
  },
  {
    name: indexkY6KMWHAqtMeta?.name ?? "template-plans",
    path: indexkY6KMWHAqtMeta?.path ?? "/template-plans",
    meta: indexkY6KMWHAqtMeta || {},
    alias: indexkY6KMWHAqtMeta?.alias || [],
    redirect: indexkY6KMWHAqtMeta?.redirect || undefined,
    component: () => import("/build/pages/template-plans/index.vue").then(m => m.default || m)
  },
  {
    name: index6DFRLHRx4YMeta?.name ?? "verizon-bulk",
    path: index6DFRLHRx4YMeta?.path ?? "/verizon-bulk",
    meta: index6DFRLHRx4YMeta || {},
    alias: index6DFRLHRx4YMeta?.alias || [],
    redirect: index6DFRLHRx4YMeta?.redirect || undefined,
    component: () => import("/build/pages/verizon-bulk/index.vue").then(m => m.default || m)
  }
]