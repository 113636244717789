import revive_payload_client_4sVQNw7RlN from "/build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_A0OWXRrUgq from "/build/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/build/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_AOuQ1DYzjk from "/build/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import view_client_f6zsCkNmU6 from "/build/plugins/view.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  plugin_AOuQ1DYzjk,
  chunk_reload_client_UciE0i6zes,
  view_client_f6zsCkNmU6
]